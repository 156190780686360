<template>
  <div class="wrapper">

    <Header />

    <div class="admin" v-if="!loading">

      <div class="admin-header">
        <h1 class="heading-r"><router-link to="/admin" class="admin-header-return"></router-link>{{ company.name }}</h1>
        <button class="button button-primary" v-on:click="showProjectCreateModal"><img src="@/assets/icon-plus.svg">Project</button>
      </div>

      <div class="admin-list" v-if="projects.length">
        <div class="admin-list-item" v-for="project in sortedProjects" :key="project._id">
          <div class="admin-list-item-name">
            <h3 class="heading-xxs">{{ project.name }}</h3>
          </div>
          <div class="admin-list-item-info">
            <div class="admin-list-item-link">
              <router-link :to="{ name: 'Project', params: { id: project.pid }}"  target="_blank">onboard.propstep.com/{{ project.pid }}</router-link>
              <span class="admin-list-item-copy" v-on:click="copyProjectLink(project.pid, $event)"></span>
            </div>
            <span class="admin-list-item-ellipsis" v-on:click="showProjectDeleteModal(project.name, project._id)"></span>
          </div>
        </div>
      </div>

      <div class="admin-empty" v-else>
        <img src="@/assets/desert.svg">
        <p>It's pretty empty in here!</p>
      </div>

    </div>

    <div class="loading" v-else>
      <img src="@/assets/loading.gif">
    </div>

    <div class="modal" v-if="createModal">
      <div class="modal-box">
        <h2 class="heading-xs">Create a project</h2>
        <form v-on:submit.prevent="createNewProject">
          <input type="text" placeholder="Project name" autocomplete="off" required v-model="newProject.name">
          <button class="button button-primary" type="submit">Create project</button>
        </form>
        <span class="modal-box-close" v-on:click="hideProjectCreateModal"></span>
      </div>
    </div>

    <div class="modal" v-if="deleteModal">
      <div class="modal-box">
        <h2 class="heading-xs">Delete this project</h2>
        <p>Are you sure you want to permanently delete <strong>{{ projectDeletion.name }}</strong>?</p>
        <button class="button button-danger" v-on:click="deleteProject(projectDeletion.id)">Delete {{ projectDeletion.name }}</button>
        <span class="modal-box-close" v-on:click="hideProjectDeleteModal"></span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Header from '@/components/Header'

export default {
  name: 'Admin',
  components: {
    Header
  },
  data () {
    return {
      loading: true,
      newProject: {
        name: ''
      },
      projectDeletion: {
        name: '',
        id: ''
      },
      company: {},
      projects: [],
      createModal: false,
      deleteModal: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    sortedProjects: function () {
      return this.projects.slice(0).sort(function (a, b) {
        return new Date(b.created) - new Date(a.created)
      })
    }
  },
  methods: {
    logOutUser: function () {
      const self = this
      this.$store.dispatch('logOutUser').then(function () {
        self.$router.push('/login')
      })
    },
    getAllProjects: function () {
      const self = this
      const company = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
      self.loading = true
      axios.get('/api/projects/' + company + '/all').then(function (response) {
        self.company = response.data.company
        self.projects = response.data.projects
        self.loading = false
        document.title = self.company.name + ' | Propstep Onboard'
      })
    },
    showProjectCreateModal: function () {
      this.createModal = true
    },
    hideProjectCreateModal: function () {
      this.createModal = false
    },
    showProjectDeleteModal: function (name, id) {
      this.projectDeletion.name = name
      this.projectDeletion.id = id
      this.deleteModal = true
    },
    hideProjectDeleteModal: function () {
      this.projectDeletion.name = ''
      this.projectDeletion.id = ''
      this.deleteModal = false
    },
    createNewProject: function () {
      const self = this
      axios.post('/api/projects/create', {
        name: this.newProject.name,
        company: this.company._id
      }).then(function (response) {
        self.projects.push(response.data)
        self.createModal = false
        self.newProject.name = ''
      })
    },
    deleteProject: function (id) {
      const self = this
      axios.post('/api/projects/' + id + '/delete', {
        company: this.company._id
      }).then(function (response) {
        self.getAllProjects()
        self.projectDeletion.name = ''
        self.projectDeletion.id = ''
        self.deleteModal = false
      })
    },
    copyProjectLink: function (pid, event) {
      navigator.clipboard.writeText('https://onboard.propstep.com/' + pid).then(function () {
        const newDiv = document.createElement('div')
        event.target.appendChild(newDiv)
        setTimeout(function () {
          newDiv.remove()
        }, 1000)
      }, function () {
        console.log('Something went wrong')
      })
    }
  },
  created () {
    this.getAllProjects()
  }
}
</script>
